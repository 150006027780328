import { Field, Query } from '@tilework/opus';

import {
    deliveryItems,
} from 'Component/ProductList/ProductList.type';

/** @namespace Steinkrueger/Query/ProductPrices/Query */
export class ProductPricesQuery {
    getQuery(options: { products: deliveryItems[] }): Query<'productPrices', string> {
        const { products } = options;

        return new Query<'productPrices', any>('productPrices')
            .addArgument('products', '[ProductInput]', products)
            .addFieldList(this._getProductPricesFields());
    }

    _getProductPricesFields(): Array<
    Field<'kolli_price', string, true>
    | Field<'product_id', string, true>
    | Field<'piece_price', string, true>
    | Field<'kg_price', string, true>
    > {
        return [
            this._getPrice(),
            this._getProductId(),
            this._getPiecePrice(),
            this._getKgPrice(),
        ];
    }

    _getPrice(): Field<'kolli_price', string, true> {
        return new Field<'kolli_price', string, true>('kolli_price', true);
    }

    _getPiecePrice(): Field<'piece_price', string, true> {
        return new Field<'piece_price', string, true>('piece_price', true);
    }

    _getKgPrice(): Field<'kg_price', string, true> {
        return new Field<'kg_price', string, true>('kg_price', true);
    }

    _getProductId(): Field<'product_id', string, true> {
        return new Field<'product_id', string, true>('product_id', true);
    }
}

export default new ProductPricesQuery();
