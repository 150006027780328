import { Location } from 'history';

import { decodeString } from 'Util/Common';

/** @namespace Steinkrueger/Util/Url/CategoryUrl/getQueryParam */
export const getQueryParam = (variable: string, location: Location): string | false => {
    const query = decodeString(location.search.substring(1));
    const vars = query.split('&');

    return vars.reduce((acc: string | false, item: string) => {
        const splitIdx = item.indexOf('=');
        const [k, v] = [item.slice(0, splitIdx), item.slice(splitIdx + 1)];

        const isCategoryUid = v.includes('category_uid');

        if (isCategoryUid) {
            return k === variable ? v : acc;
        }

        return k === variable ? v.replace(/=/g, ':') : acc;
    }, false);
};
