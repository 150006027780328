import {
    CategoryFilterOverlay as SourceCategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import {ReactElement} from 'Type/Common.type';
import {isCrawler, isSSR} from 'Util/Browser';

import './CategoryPage.override.style';

export {
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

export const CategoryFilterOverlay = SourceCategoryFilterOverlay;

/** @namespace Steinkrueger/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    renderTitle(title: string | undefined): ReactElement {
        return <h1 block="Category" elem="Title">{ title }</h1>;
    }

    renderMiscellaneous(): ReactElement {
        const { totalItems, isMobile, category: {name} } = this.props;

        if (totalItems === 0 || !this.displayProducts()) {
            return <aside block="CategoryPage" elem="Miscellaneous" mods={ { noResults: true } } />;
        }

        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                { this.renderItemsCount() }
                { isMobile && name && this.renderTitle(name) }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderLayoutButtons() }
                        { this.renderFilterButton() }
                    </div>
                    <div
                      block="CategoryPage"
                      elem="LayoutWrapper"
                      mods={ { isPrerendered: isSSR() || isCrawler() } }
                    >
                        { this.renderCategorySort() }
                    </div>
                </div>
            </aside>
        );
    }
}

export default CategoryPageComponent;
