import ProductCard from 'Component/ProductCard';
import {CategoryPageLayout} from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import {DEFAULT_PLACEHOLDER_COUNT} from 'SourceComponent/ProductListPage/ProductListPage.config';
import {ReactElement} from 'Type/Common.type';
import {PRODUCTS_PRELOAD_COUNT} from 'Util/Product';
import {setLoadedFlag} from 'Util/Request/LowPriorityLoad';
import {AfterPriority} from 'Util/Request/LowPriorityRender';

/** @namespace Steinkrueger/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    renderPlaceholders(): ReactElement {
        const {
            numberOfPlaceholders = DEFAULT_PLACEHOLDER_COUNT,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => {
                if (i < PRODUCTS_PRELOAD_COUNT) {
                    return (
                        <ProductCard
                          key={ i }
                          product={ {} }
                          layout={ layout as CategoryPageLayout }
                          onLoad={ setLoadedFlag }
                        />
                    );
                }

                return (
                    // @ts-ignore
                    <AfterPriority
                      fallback={ <div style={ { minHeight: 200 } } /> }
                      key={ i }
                    >
                        <ProductCard
                          product={ {} }
                          layout={ layout as CategoryPageLayout }
                          onLoad={ setLoadedFlag }
                        />
                    </AfterPriority>
                );
            },
        );
    }

    renderPageItems(): ReactElement {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <ProductCard
                      product={ product }
                        // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout as CategoryPageLayout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                    />
                );
            }

            return (
                // @ts-ignore
                <AfterPriority
                  fallback={ <div style={ { minHeight: 200 } } /> }
                    // eslint-disable-next-line react/no-array-index-key
                  key={ i }
                >
                    <ProductCard
                      product={ product }
                      selectedFilters={ selectedFilters }
                      layout={ layout as CategoryPageLayout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                    />
                </AfterPriority>
            );
        });
    }
}

export default ProductListPageComponent;
