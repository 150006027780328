import { connect } from 'react-redux';

import {ProductAttributeFilterOptions} from 'Query/ProductList.type';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url/CategoryUrl';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Steinkrueger/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    getFilter(): ProductAttributeFilterOptions {
        const { categoryIds, category: { id } } = this.props;
        const customFilters = this.getSelectedFiltersFromUrl();
        const priceRange = this.getSelectedPriceRangeFromUrl();

        if (categoryIds === -1) {
            if (id) {
                return {
                    priceRange,
                    customFilters,
                    categoryIds: +id,
                };
            }

            return {
                priceRange,
                customFilters,
            };
        }

        return {
            priceRange,
            customFilters,
            categoryIds,
        };
    }

    getIsMatchingInfoFilter(): boolean {
        const {
            categoryIds,
            selectedInfoFilter: {
                categoryIds: selectedCategoryIds = window.actionName?.id,
            },
        } = this.props;

        if (categoryIds === -1) {
            return true;
        }

        // Requested category is equal to current category
        return categoryIds === selectedCategoryIds;
    }

    isCurrentCategoryLoaded(): boolean {
        const {
            categoryIds,
            category: {
                id,
            },
            isSearchPage,
        } = this.props;

        if (categoryIds === -1) {
            return true;
        }

        return isSearchPage || categoryIds === id;
    }

    getSelectedFiltersFromUrl() {
        const { location } = history;
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

        return selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [ key ]: value.split(',') };
        }, {});
    }

    getIsMatchingListFilter(): boolean {
        const {
            currentArgs: {
                currentPage,
                sort,
                filter,
            } = {},
            isSearchPage,
        } = this.props;
        const { location } = history;

        if (isSearchPage) {
            return true;
        }

        // Data used to request category matches current data
        return JSON.stringify(filter) === JSON.stringify(this.getFilter())
            && JSON.stringify(sort) === JSON.stringify(this.getSelectedSortFromUrl())
            && currentPage === +(getQueryParam('page', location) || 1);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    CategoryPageContainer,
);
